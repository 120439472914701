import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import { Button } from "../../../components/Button";
import { selectCurrentUser, selectCurrentUserId } from "../../../store/models/User";
import { ModalTypes, Urls, UserRoleIds as UserRoles } from "../../../constants";
import UIState from "../../../store/UIState";
import { AiTab, AiTabs } from "../../../components/AiTabs";
import { StripeIcon } from "../../../components/Svg";
import classNames from "classnames";
import Bill, { selectAutoRechargeSetting, selectBalance, selectBalanceInfo, selectBills, selectTotalBillPages } from "../../../store/models/Bill";
import { selectCurrentOrg, selectCurrentOrgId } from "../../../store/models/Organization";
import { formatBalance, formatDate, formatPrice } from "../../../utils";
import { IoMdCheckmark } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { replaceTo } from "../../../store/history";
import { Pagination } from "../../../components/Pagination";
import _ from "lodash";
import { getQueryParams } from "../../../utils/url";
import Organization from "../../../store/models/Organization"; // Add this import
import AppState from "../../../store/AppState";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  selectCurrentOrgId,
  selectCurrentOrg,
  (currentUserId, currentUser, orgId, org) => {
    return {
      currentUserId,
      currentUser,
      orgId,
      org
    }
  }
)

export const BillingPage = () => {

  const dispatch = useDispatch();
  const { currentUserId, currentUser, orgId, org } = useSelector(state => selector(state));
  const balanceInfo = useSelector(state => selectBalanceInfo(state, orgId)) || {};
  const { auto_recharge_enabled: autoRechargeSetting, balance, stripe_subscription: subscription } = balanceInfo;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('overview');
  const location = useLocation();
  const [linkLoading, setLinkLoading] = useState(false);
  const bills = useSelector(state => selectBills(state, orgId));
  const totalPages = useSelector(state => selectTotalBillPages(state));
  const [crtBillPage, setCrtBillPage] = useState(1);
  const [billLoading, setBillLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { org_id, coupon } = getQueryParams(location.search);

      if (org_id && org_id !== orgId) {
        await dispatch(AppState.actions.selectCurrentOrgAndProject(org_id));
        const url = new URL(window.location.href);
        url.searchParams.delete('org_id');
        replaceTo(url.pathname + url.search);
      } else {
        fetchData();
      }

      if (coupon) {
        dispatch(UIState.actions.showModal(ModalTypes.ADD_CREDIT, { coupon, couponOnly: !subscription }))
      }
    })()
  }, [location, orgId]);

  useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      if (currentUser.role !== UserRoles.ADMIN)
        replaceTo(Urls.SETTINGS_PROFILE);
    }
  }, [currentUser, currentUser?.role])

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    // await dispatch(Bill.actions.fetchAutoRechargeSetting(orgId))
    try {
      await dispatch(Bill.actions.fetchBalance(orgId));
      await dispatch(Bill.actions.fetchBillHistory(orgId, 1, 20));
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const openCancelModal = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: `Cancel Plan`,
      message: `Do you want to cancel your stripe plan?`,
      confirmLabel: `Cancel Plan`,
      cancelLabel: `Close`,
      onConfirm: async () => {
        try {
          await dispatch(Bill.actions.cancelPlan(orgId));
          await dispatch(Bill.actions.fetchBalance(orgId));
          toast.success('Plan is successfully cancelled.');
          return;
        } catch (e) {
          toast.error(e.message)
        }
      }
    }));
  }

  const openAutoRechargeModal = () => {
    dispatch(UIState.actions.showModal(ModalTypes.AUTO_RECHARGE))
  }

  const openAddCreditModal = () => {
    dispatch(UIState.actions.showModal(ModalTypes.ADD_CREDIT))
  }

  const openApplyCouponModal = () => {
    dispatch(UIState.actions.showModal(ModalTypes.ADD_CREDIT, { couponOnly: true }))
  }

  const handleLink = async () => {
    setLinkLoading(true);
    const currentUrl = window.location.protocol + '//' + window.location.host + location.pathname + location.search;

    let url = await dispatch(Bill.actions.fetchStripeUrl(orgId, currentUrl));
    console.log(url)

    // window.open(url);
    window.location.href = url;
    // setLinkLoading(false);
  }

  const handleBillPageChange = async pageNumber => {
    setCrtBillPage(pageNumber);
    setBillLoading(true);
    try {
      await dispatch(Bill.actions.fetchBillHistory(orgId, pageNumber, 20));
      setBillLoading(false);
    } catch (e) {
      setError(e.message)
      setBillLoading(false);
    }
  }

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>)

  return (<div className={"BillingPage"}>
    <PageTitleBar alignTitleLeft={true} title={"Billing"} />
    <div className={"BillingPage__content"}>
      <AiTabs>
        <AiTab isSelected={(selectedTab === 'overview')}
          onClick={() => {
            setSelectedTab('overview')
          }}>
          Overview
        </AiTab>
        <AiTab isSelected={(selectedTab === 'payment-method')}
          onClick={() => {
            setSelectedTab('payment-method')
          }}>
          Payment Method
        </AiTab>
        <AiTab isSelected={(selectedTab === 'history')}
          onClick={() => {
            setSelectedTab('history')
          }}>
          Billing History
        </AiTab>
      </AiTabs>
      {loading && <Loader />}
      {
        (selectedTab === 'overview') && !loading &&
        <div className='BillingPage__tab-content'>
          <div className='BillingPage__text'>Pay as you go</div>
          <div className='BillingPage__balance'>
            <div className='BillingPage__balance--title'>
              Credit Balance <span className='BillingPage__balance--title-org'>({org.name})</span>
            </div>
            <div className='BillingPage__balance--credit'>
              {formatBalance(balance)}
            </div>
          </div>
          {subscription && <div className='BillingPage__block'>
            <div className='BillingPage__block--overview-title'>
              Auto recharge is {autoRechargeSetting ? 'on' : 'off'}
            </div>
            <div className='BillingPage__block--overview-description'>
              When your credit balance reaches $0, your service could get disrupted. Enable automatic recharge to automatically keep your credit balance topped up.
            </div>
            <div className={classNames('BillingPage__block--overview-status')} onClick={openAutoRechargeModal}>
              {autoRechargeSetting && <IoMdCheckmark />}
              {!autoRechargeSetting ? 'Enable' : 'Manage'} auto recharge
            </div>
          </div>}
          {subscription && <div className='BillingPage__buttons'>
            <Button color={"green"} onClick={openAddCreditModal} title={"Add to credit balance"} loading={loading} />
            <Button color={"green-outline"} onClick={openCancelModal} title={"Cancel plan"} loading={loading} />
          </div>}
          {!subscription && <div className='BillingPage__buttons'>
            <Button color={"green"} onClick={handleLink} title={"Link Stripe Account"} loading={linkLoading} />
            <Button color={"green"} onClick={openApplyCouponModal} title={"Apply coupon"} loading={loading} />
          </div>
          }
        </div>
      }
      {
        (selectedTab === 'payment-method') && !loading &&
        <div className='BillingPage__tab-content'>
          <div className='BillingPage__block'>
            <div className='BillingPage__block--header'>
              <div className='BillingPage__block--icon-wrap'>
                <div className='BillingPage__block--icon'>
                  <StripeIcon />
                </div>
                <div className="BillingPage__block--payment-title">
                  Pay with Stripe
                </div>
              </div>
              <Button color={"green"} onClick={handleLink} title={subscription ? "Manage Account" : "Link Account"} loading={linkLoading} />

              {/* <div className="BillingPage__block--status">
                {subscription ? "Manage Account" : "Link Account"}
              </div> */}
            </div>
            {/* <div className='BillingPage__block--hr'></div> */}
          </div>
          <div className='BillingPage__block'>
            <div className='BillingPage__block--header'>
              <div className='BillingPage__block--icon-wrap'>
                <div className='BillingPage__block--icon'>
                  <div className='BillingPage__block--header-logo tfuel'></div>
                </div>
                <div className="BillingPage__block--payment-title">
                  Pay with TFuel
                </div>
              </div>
              <div className='BillingPage__block--content'>
                Coming soon!
              </div>
            </div>
          </div>
        </div>
      }
      {
        (selectedTab === 'history') && !loading &&
        <div className='BillingPage__tab-content'>
          {billLoading && <Loader />}
          {!billLoading && !bills && <div className={'EmptyState'}>
            <div className={'EmptyState__title'}>No bills yet.</div>
          </div>}
          {!billLoading && bills && <div className='BillingPage__table'>
            <div className='BillingPage__table--header'>
              <div className='BillingPage__table--header-row'>
                <div className='BillingPage__table--cell invoice'>
                  Invoice
                </div>
                <div className='BillingPage__table--cell status'>
                  Status
                </div>
                <div className='BillingPage__table--cell amount'>
                  Amount
                </div>
                <div className='BillingPage__table--cell created'>
                  Created
                </div>
                <div className='BillingPage__table--cell view'>
                </div>
              </div>
            </div>
            {bills.map((bill, i) => {
              const status = bill.status === 'open' ? 'failed' : bill.status;
              return (<div className='BillingPage__table--row' key={i}>
                <div className='BillingPage__table--cell invoice'>
                  {bill.id}
                </div>
                <div className={`BillingPage__table--cell status ${status}`}>
                  {status}
                </div>
                <div className='BillingPage__table--cell amount'>
                  ${formatPrice(Number(bill.total) / 100)}
                </div>
                <div className='BillingPage__table--cell created'>
                  {formatDate(bill.create_time)}
                </div>
                <div className='BillingPage__table--cell view'>
                  <a href={bill.hosted_invoice_url} target='_blank'>
                    <div className={classNames('AiServicePageViewButton active')}>
                      View
                    </div>
                  </a>
                </div>
              </div>)
            })}
          </div>}
          {!billLoading && bills && <div className='BillingPage__pagination'>
            <Pagination
              size={'lg'}
              currentPage={crtBillPage}
              totalPages={totalPages}
              onPageChange={handleBillPageChange} />
          </div>}
        </div>
      }
    </div>
  </div>
  )
}
