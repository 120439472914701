import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon, CopyIcon, DeleteIcon, InfoIcon, RotateIcon, UploadIconIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Hosts, ModalTypes,
  RAGChatbotMaxTemp, RAGChatbotMaxTokens, RAGChatbotMinTemp, RAGChatbotMinTokens,
  RAGChatbotTempStep, RAGChatbotTokenStep, Urls
} from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { getRagChatbotIframeUrl, selectRagChatbot } from '../../../store/models/Ai';
import cx from 'classnames';
import Loader from '../../../components/Loader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import useDebounce from '../../../hooks/useDebounce';
import { useDropzone } from 'react-dropzone';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import Slider from '../../../components/Slider';
import { ChromePicker } from 'react-color';
import { TextInput } from '../../../components/form/TextInput';
import { SelectInput } from '../../../components/form/SelectInput';
import UIState from '../../../store/UIState';
import { GrAdd } from "react-icons/gr";
import KnowledgeBase from '../../../components/KnowledgeBase';
import { Pagination } from '../../../components/Pagination';
import { isEmpty, isNil } from 'lodash';
import Tooltip from '../../../components/Tooltip';
import { ChatbotColors } from '../../../constants/chatbot-colors';


function RagChatbotDetailPage() {
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedSubTab, setSelectedSubTab] = useState('playground');
  const [accessToken, setAccessToken] = useState();

  const chatbot = useSelector(state => selectRagChatbot(state, projectId, id));

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [projectId, id]);

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
      let res = await dispatch(Ai.actions.fetchRagAccessToken(projectId, id));
      setAccessToken(res);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }



  return (<div className={'AiServicePage'}>
    <Link to={mergeUrl(Urls.AI_RAG_CHATBOT, { projectId })}>
      <div className={'AiServicePage__back-button rag-chatbot'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          RAG Chatbot
        </div>
      </div>
    </Link>
    {!loading && chatbot && <div className={'AiServicePage__header detail'}>
      <div className={'AiServicePage__header--title'}>{chatbot.name || 'RAG Chatbot'}</div>
    </div>}
    {!loading && chatbot && <div className={'AiServicePage__content new-deployment'}>
      <AiTabs>
        <AiTab isSelected={(selectedSubTab === 'playground')}
          onClick={() => {
            setSelectedSubTab('playground')
          }}>
          Playground
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'integration')}
          onClick={() => {
            setSelectedSubTab('integration')
          }}>
          Integration
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'settings')}
          onClick={() => {
            setSelectedSubTab('settings')
          }}>
          Settings
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'knowledge-base')}
          onClick={() => {
            setSelectedSubTab('knowledge-base')
          }}>
          Knowledge base
        </AiTab>
      </AiTabs>
      {
        (selectedSubTab === 'playground') && chatbot &&
        <PlaygroundTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'integration') && chatbot &&
        <IntegrationTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'settings') && chatbot &&
        <div className='AiServicePage__tab-content'>
          <SettingTab chatbot={chatbot} projectId={projectId} accessToken={accessToken} setAccessToken={setAccessToken} />
        </div>
      }
      {
        (selectedSubTab === 'knowledge-base') && chatbot &&
        <KnowledgeBaseTab chatbot={chatbot} />
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !chatbot && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

const PlaygroundTab = (props) => {
  const { chatbot, accessToken } = props;

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const iframeUrl = useMemo(() =>
    getRagChatbotIframeUrl(chatbot, accessToken), [chatbot.llm_endpoint, accessToken]);

  const onCopy = () => {
    navigator.clipboard.writeText(iframeUrl)
      .then(() => {
        toast.success('Chatbot URL Copied!')
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }
  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage playground'>
      <div className='RagChatbotDetailPage__row'>

        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label-row'>
            <div className='RagChatbotDetailPage__input-label'>
              Chatbot URL <div className='RagChatbotDetailPage__input-label--icon' onClick={onCopy}><CopyIcon /></div>
            </div>
            <Button href={iframeUrl} target='_blank' title={"Open"} size={'small'} color={'green-outline'} />
          </div>

          <div className='RagChatbotDetailPage__input-div'>
            <div className='RagChatbotDetailPage__input-div--info RagChatbotDetailPage__input-div--one-line'>{iframeUrl}</div>
          </div>
        </div>
      </div>
      <div className='RagChatbotDetailPage__playground'>
        <iframe src={iframeUrl} width='100%' height='100%' />
      </div>
    </div>
  </div >
}

const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: 'white',
    },
    '.selected': {
      borderColor: 'white',
    },
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles, color: "#fff" }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#fff', fontWeight: '500' }),
};



const getReadableColorName = (cssVarName) => {
  return cssVarName
    .replace('--color-', '')
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const SettingTab = (props) => {
  const { chatbot, projectId, accessToken, setAccessToken } = props;
  const dispatch = useDispatch();
  const endpointOptions = chatbot.llm_endpoints.filter(c => c.id.startsWith('llm_')).map(c => ({ label: c.name, value: c.id }));
  const customEndpoints = chatbot.llm_endpoints.filter(c => c.id.startsWith('c_llm_'))
  const customEndpointOptions = customEndpoints.map(c => ({ label: c.name || c.id, value: c.id, type: 'custom' })).concat({ label: 'Add your own LLM endpoint', value: 'add-custom', type: 'add-custom' });
  let groupedOptions = [{
    label: 'Standard',
    options: endpointOptions
  }, {
    label: 'Custom',
    options: customEndpointOptions
  }]
  // const [endpointOptions, setEndpointOptions] = useState(chatbot.filter(c => c.id.startsWith('llm_')).map(c => ({ label: c.name, value: c.id })));
  const [value, setValue] = useState(() => {
    let id = _.get(chatbot, 'ui.llm_endpoint_id');
    let option = endpointOptions.find(e => e.value === id);
    if (!option) {
      option = customEndpointOptions.find(e => e.value === id);
    }
    return option;
  });

  const [maxTokens, setMaxTokens] = useState(chatbot.max_tokens);
  const [temperature, setTemperature] = useState(chatbot.temperature);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState(_.get(chatbot, 'ui.preset_questions') || []);
  const [showColorSettings, setShowColorSettings] = useState(false);
  const [colors, setColors] = useState(() => {
    const savedColors = _.get(chatbot, 'ui.css_colors', {});
    return ChatbotColors.reduce((acc, { key, default: defaultValue }) => {
      acc[key] = savedColors[key] || defaultValue;
      return acc;
    }, {});
  });

  const endpintRef = useRef();
  const sysMsgRef = useRef();
  const tokenRef = useRef();
  const defaultDialogRef = useRef();
  const avatarUrlRef = useRef();
  const onEndpointChange = (v) => {
    if (v.value === 'add-custom') {
      dispatch(UIState.actions.showModal(ModalTypes.UPDATE_LLM_ENDPOINT, { type: 'create', projectId, chatbotId: chatbot.id }))
      return;
    }
    setValue(v);
  }

  const onSetMaxTokens = (event) => {
    const value = event.target.value;
    if (_.isEmpty(value)) {
      setMaxTokens(0)
    } else if (value < RAGChatbotMinTokens) {
      setMaxTokens(RAGChatbotMinTokens)
    } else if (value > RAGChatbotMaxTokens) {
      setMaxTokens(RAGChatbotMaxTokens)
    } else {
      setMaxTokens(value)
    }
  }

  const onSetTemperature = (event) => {
    const value = event.target.value;
    if (value < RAGChatbotMinTemp) {
      setTemperature(RAGChatbotMinTemp)
    } else if (value > RAGChatbotMaxTemp) {
      setTemperature(RAGChatbotMaxTemp)
    } else {
      setTemperature(value)
    }
  }

  const handleThemeChange = (newTheme) => {
    setColors(prevColors => {
      return ChatbotColors.reduce((acc, { key, default: defaultValue, light, vgk }) => {
        acc[key] = newTheme === 'light' ? light : (newTheme === 'vgk' ? vgk : defaultValue);
        return acc;
      }, {});
    });
  };

  const handleColorChange = (colorKey, color) => {
    setColors(prevColors => ({
      ...prevColors,
      [colorKey]: color
    }));
  };

  const handleUpdate = async () => {
    if (!endpintRef.current.state.selectValue[0]) {
      endpintRef.current.focus();
      setError("LLM Endpoint can't be empty.");
      return;
    }
    let endpoint = _.get(endpintRef, 'current.state.selectValue[0].value');

    setLoading(true);
    setError(null);
    try {
      let obj = {
        name: chatbot.name,
        project_id: projectId,
        llm_endpoint_id: endpoint,
        max_tokens: maxTokens,
        temperature: temperature,
        system_prompt: sysMsgRef.current.value,
        icon: avatarUrlRef.current.value,
        preset_questions: questions,
        default_dialog: defaultDialogRef.current.value,
        css_colors: colors
      };
      await dispatch(Ai.actions.updateRagChatbot(chatbot.id, obj));
      await dispatch(Ai.actions.fetchRagChatbot(projectId, chatbot.id));
      setLoading(false);
      toast.success(`Chatbot ${chatbot.name} has been updated!`)
    } catch (e) {
      console.log('error:', e.message)
      setLoading(false);
      setError(e.message);
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(accessToken)
      .then(() => {
        toast.success('Access Token Copied!')
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }

  const handleRotate = () => {
    const onConfirm = async () => {
      try {
        let res = await dispatch(Ai.actions.rotateRagAccessToken(projectId, chatbot.id));
        setAccessToken(res);
        toast.success(`Access token has been updated.`)
      } catch (e) {
        toast.error(`Oops, something went wrong. Error: ${e.message}`)
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: `Rotate access token`,
      message: `Do you want to update the access token?`,
      confirmLabel: `Rotate`,
      cancelLabel: `Close`,
      onConfirm
    }))
  }

  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage settings'>
      <div className='RagChatbotDetailPage__row'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label'>LLM Endpoint</div>
          <CreatableSelect
            options={groupedOptions}
            className={"RagChatbotDetailPage__selector"}
            placeholder={"LLM Endpoint, e.g. thetalabsorg/ubuntu-sshd:latest"}
            styles={selectStyles}
            onChange={onEndpointChange}
            ref={endpintRef}
            value={value}
            isDisabled={!groupedOptions}
            components={{ Option: (props) => <CustomOption {...props} customEndpoints={customEndpoints} selectedId={_.get(chatbot, 'ui.llm_endpoint_id')} /> }}
          />
        </div>
      </div>
      <div className='RagChatbotDetailPage__row'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label'>Access token</div>
          <div className='RagChatbotDetailPage__input-div token'>
            <div className='RagChatbotDetailPage__input-div--info'>{accessToken}</div>
            <div className='RagChatbotDetailPage__input-div--buttons'>
              <div className='RagChatbotDetailPage__input-div--icon' onClick={handleCopy}>
                <CopyIcon />
              </div>
              <div className='RagChatbotDetailPage__input-div--icon' onClick={handleRotate}>
                <RotateIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='RagChatbotDetailPage__row large-gap'>
        <div className='RagChatbotDetailPage__input-wrap slider'>
          <div className='RagChatbotDetailPage__input-label'>Max tokens</div>
          <Slider className='RagChatbotDetailPage__slider-wrap' step={RAGChatbotTokenStep} id="maxTokensId"
            max={RAGChatbotMaxTokens} min={RAGChatbotMinTokens} value={maxTokens} onChange={onSetMaxTokens} />
        </div>
        <div className='RagChatbotDetailPage__input-wrap slider'>
          <div className='RagChatbotDetailPage__input-label'>Temperature</div>
          <Slider className='RagChatbotDetailPage__slider-wrap' step={RAGChatbotTempStep} id="temperatureId"
            max={RAGChatbotMaxTemp} min={RAGChatbotMinTemp} value={temperature} onChange={onSetTemperature} />
        </div>
        <div className='RagChatbotDetailPage__input-wrap slider-empty'></div>
      </div>

      <div className='RagChatbotDetailPage__row'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label'>System message
            <Tooltip tooltip={"Give instructions to the chatbot on how to respond to user messages, give a personality to the chatbot. Not visible to users."}>
              <InfoIcon />
            </Tooltip>
          </div>
          <textarea ref={sysMsgRef}
            className='RagChatbotDetailPage__textarea'
            defaultValue={_.get(chatbot, 'system_prompt')} />
        </div>
      </div>
      <div className='RagChatbotDetailPage__row'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label'>Default dialog
            <Tooltip tooltip={"Welcome message displayed when the chatbot is opened."}>
              <InfoIcon />
            </Tooltip>
          </div>
          <input ref={defaultDialogRef}
            className='RagChatbotDetailPage__input'
            defaultValue={_.get(chatbot, 'ui.default_dialog')} />
        </div>
      </div>

      <div className='RagChatbotDetailPage__row'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label'>Chatbot avatar URL
            <Tooltip tooltip={"Avatar image displayed in front of the chatbot."}>
              <InfoIcon />
            </Tooltip>
          </div>
          <input ref={avatarUrlRef}
            className='RagChatbotDetailPage__input'
            defaultValue={_.get(chatbot, 'ui.icon')} />
        </div>
      </div>

      <div className='RagChatbotDetailPage__row large-gap'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label'>Preset questions
            <Tooltip tooltip={"Questions will be displayed at the bottom of the chatbot. Answers are optional, if not provided, the chatbot will generate an answer."}>
              <InfoIcon />
            </Tooltip>
          </div>
          <div className='RagChatbotDetailPage__preset-questions' >
            <div className='RagChatbotDetailPage__preset-questions--wrap'>
              {/* <input type='text' className='RagChatbotDetailPage__input' /> */}
              <QuestionsSection questions={questions} setQuestions={setQuestions} />
              <div className='RagChatbotDetailPage__preset-questions--icon' onClick={() => setQuestions(q => q.concat({ question: '', answer: '' }))}>
                <UploadIconIcon /> New
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='RagChatbotDetailPage__row'>
        <div className='RagChatbotDetailPage__input-wrap'>
          <div
            className='RagChatbotDetailPage__input-label collapsible'
            onClick={() => setShowColorSettings(!showColorSettings)}
          >
            Chatbot Colors {showColorSettings ? '▼' : '►'}
          </div>
          {showColorSettings && (
            <div className='RagChatbotDetailPage__color-settings-container'>
              <div className='RagChatbotDetailPage__color-picker'>
                <label>Apply color theme</label>
                <select
                  onChange={(e) => handleThemeChange(e.target.value)}
                  className='RagChatbotDetailPage__color-theme-selector'
                  defaultValue=""
                >
                  <option value="" disabled>Choose a theme to apply</option>
                  <option value="default">Dark</option>
                  <option value="light">Light</option>
                  {/* <option value="vgk">VGK</option> */}
                </select>
              </div>

              <div className='RagChatbotDetailPage__color-settings'>
                {ChatbotColors.map(({ key }) => (
                  <div key={key} className='RagChatbotDetailPage__color-picker'>
                    <label>{getReadableColorName(key)}</label>
                    <ColorPicker
                      color={colors[key]}
                      setColor={(color) => handleColorChange(key, color)}
                    />
                  </div>
                ))}
              </div>
            </div>)}
        </div>
      </div>

      <div className='RagChatbotDetailPage__row center'>
        <Button color="green" title={"Update"} onClick={handleUpdate} size={'large'} loading={loading} disabled={loading} />
      </div>
    </div>
  </div >
}

const CustomOption = ({ data, customEndpoints, selectedId, ...props }) => {
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const onEdit = (e, endpoints) => {
    e.stopPropagation();
    const endpoint = _.get(endpoints, 0);

    dispatch(UIState.actions.showModal(ModalTypes.UPDATE_LLM_ENDPOINT, { type: 'edit', endpoint, projectId, chatbotId: id }))
    return;
  }
  const onDelete = (e, endpoints) => {
    e.stopPropagation();
    const endpoint = _.get(endpoints, 0);
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteLlmEndpoint(projectId, id, endpoint.id));
      await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: endpoint.name || endpoint.id, onConfirm }));
  }
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: data.type === 'custom' ? 'space-between' : 'flex-start' }}>
        {data.value === 'add-custom' && <GrAdd style={{ marginRight: 5 }} />}
        <span>{data.label}</span>
        {data.type === 'custom' && <div>
          <Button color='green-outline' title="Edit" size='small'
            onClick={(e) => onEdit(e, customEndpoints.filter(e => e.id === data.value))} />
          <Button color='red-outline' title="Remove" size='small' style={{ marginLeft: 5 }}
            disabled={data.value === selectedId}
            onClick={(e) => onDelete(e, customEndpoints.filter(e => e.id === data.value))} />
        </div>}
      </div>
    </components.Option>
  );
}


const QuestionsSection = ({ questions, setQuestions }) => {

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = value;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].answer = value;
    setQuestions(updatedQuestions);
  };

  return <div className='RagChatbotDetailPage__questions'>
    {questions.map((q, i) => {
      return <div className='RagChatbotDetailPage__question-container' key={i}>
        <div className='RagChatbotDetailPage__question'>
          <div className='RagChatbotDetailPage__question--wrap'>
            <div className='RagChatbotDetailPage__question--label'>Question #{i + 1}</div>
            <input className='RagChatbotDetailPage__question--input'
              type="text" placeholder={`Enter question #${i + 1}`}
              value={q.question}
              onChange={(e) => handleQuestionChange(i, e.target.value)} />
          </div>
          <div className='RagChatbotDetailPage__question--wrap'>
            <div className='RagChatbotDetailPage__question--label'>Answer #{i + 1}</div>
            <input className='RagChatbotDetailPage__question--input'
              type="text" placeholder={`Enter answer #${i + 1}, or leave blank if you want the chatbot to generate the answer dynamically`}
              value={q.answer}
              onChange={(e) => handleAnswerChange(i, e.target.value)} />
          </div>
        </div>
        <div className='RagChatbotDetailPage__question-icon-container'>
          <div className='RagChatbotDetailPage__question-delete-icon'
            onClick={() => setQuestions(pre => pre.filter((_, idx) => idx !== i))}>
            <DeleteIcon />
          </div>
        </div>
      </div>
    })}
  </div >
}

const ColorPicker = (props) => {
  const { color, setColor } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(v => !v)
  }

  const handleClose = () => {
    setDisplayColorPicker(false);
  }

  const handleChange = (color) => {
    setColor(color.hex)
  }

  return <div className='RagChatbotDetailPage__color'>
    <div className='RagChatbotDetailPage__color--swatch' onClick={handleClick}>
      <div className='RagChatbotDetailPage__color--color' style={{ background: color }} />
      <div className='RagChatbotDetailPage__color--hexcode'>{color}</div>
    </div>
    {displayColorPicker && <div className='RagChatbotDetailPage__color--popover'>
      <div className='RagChatbotDetailPage__color--cover' onClick={handleClose} />
      <ChromePicker color={color} onChange={handleChange} />
    </div>}
  </div>
}

const IntegrationTab = (props) => {
  const { chatbot, accessToken } = props;
  const [tab, setTab] = useState('curl');

  return (
    <div className='AiServicePage__tab-content'>
      <div className='RagChatbotDetailPage integration'>
        <div className='RagChatbotDetailPage__tabs'>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'curl' })} onClick={() => setTab('curl')}>
            cURL
          </div>
          {/* <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'js' })} onClick={() => setTab('js')}>
            JavaScript
          </div>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'python' })} onClick={() => setTab('python')}>
            Python
          </div> */}
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'iframe' })} onClick={() => setTab('iframe')}>
            iFrame
          </div>
        </div>
        <CodeIntegration tab={tab} chatbot={chatbot} accessToken={accessToken} />
      </div>
    </div>
  );
}

const CodeIntegration = ({ tab, chatbot, accessToken }) => {
  const { model, llm_endpoint, max_tokens, id, temperature } = chatbot;
  const link = (llm_endpoint || '').split('/v1')[0];
  const iframeUrl = getRagChatbotIframeUrl(chatbot, accessToken);

  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState(`curl -X POST ${Hosts.CHATBOT_API}/chatbot/${id}/chat/completions \\
  -H 'Content-Type: application/json' \\
  -H "Authorization: Bearer ${accessToken}" \\
  -d '{
    "messages": [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Hello, how are you?"}
    ],
  }'`);
  useEffect(() => {
    const curlCode = `curl -X POST ${Hosts.CHATBOT_API}/chatbot/${id}/chat/completions \\
    -H 'Content-Type: application/json' \\
    -H "Authorization: Bearer ${accessToken}" \\
    -d '{
      "messages": [
          {"role": "system", "content": "You are a helpful assistant."},
          {"role": "user", "content": "Hello, how are you?"}
      ],
      "max_tokens": ${max_tokens || 100},
      "temperature": ${temperature || 0.7},
      "stream": true
    }'`;
    const jsCode = `import OpenAI from "openai";

// Set OpenAI's API key to use vLLM's API server.
const openai_api_key = "YOUR_OPENAI_API_KEY"
const openai_api_base = "${link}/v1"

const openai = new OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
);

async function main() {
  const completion = await openai.completions.create({
    "model": "${model}",
    "messages": [
      {"role": "system", "content": "You are a helpful assistant."},
      {"role": "user", "content": "Hello, how are you?"}
    ],
    "max_tokens": ${max_tokens || 100}
  });
    
  // console.log(completion);
}
main();`;

    const pythonCode = `from openai import OpenAI

# Set OpenAI's API key to use vLLM's API server.
openai_api_key = "YOUR_OPENAI_API_KEY"
openai_api_base = "${link}/v1"

client = OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
)

chat_response = client.chat.completions.create({
  "model": "${model}",
  "messages": [
    {"role": "system", "content": "You are a helpful assistant."},
    {"role": "user", "content": "Hello, how are you?"}
  ],
  "max_tokens": ${max_tokens || 100}
})
print("Chat response:", chat_response)`;

    const iframeCode = `<iframe src="${iframeUrl}"></iframe>`;

    switch (tab) {
      case 'curl':
        setCode(curlCode);
        break;
      case 'js':
        setCode(jsCode);
        break;
      case 'python':
        setCode(pythonCode);
        break;
      case 'iframe':
        setCode(iframeCode);
        break;
      default:
        setCode();
        break;
    }
  }, [tab, chatbot]);

  function getLanguage(tab) {
    switch (tab) {
      case 'curl':
        return 'javascript';
      case 'js':
        return 'javascript';
      case 'python':
        return 'python';
      case 'iframe':
        return 'html';
      default:
        return 'javascript';
    }
  }

  function getTitle(tab) {
    switch (tab) {
      case 'curl':
        return 'cURL code';
      case 'js':
        return 'JavaScript code';
      case 'python':
        return 'Python code';
      case 'iframe':
        return 'iFrame embed';
      default:
        return 'javascript';
    }
  }

  const handleCopy = () => {
    if (copied) return;
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopied(true);
        toast.success('Code Copied!')
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }

  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage integration'>
      <div className='RagChatbotDetailPage__code-content'>
        <div className='RagChatbotDetailPage__code-content--title'>{getTitle(tab)}</div>
        <div className='RagChatbotDetailPage__code-content--code'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language={getLanguage(tab)}
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>
        <Button onClick={handleCopy} title={copied ? "Code Copied!" : "Copy Code"} color={'green-outline'} />

      </div>
    </div>
  </div>
}

const KnowledgeBaseTab = (props) => {
  const dispatch = useDispatch();
  const { chatbot } = props;
  const { projectId, id } = useParams();
  const [error, setError] = useState();
  const [crtPage, setCrtPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData()
  }, [chatbot.id])

  const fetchData = async (page = 0) => {
    try {
      setError()
      setLoading(true);
      let res = await dispatch(Ai.actions.fetchChatbotDocuments(id, projectId, page));
      setDocuments(_.get(res, 'documents'));
      setTotalPages(_.get(res, 'total_pages'));
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = pageNumber => {
    setCrtPage(pageNumber);
    fetchData(pageNumber - 1);
  }

  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage knowledge-base-tab'>
      <NewDocument fetchData={fetchData} />
      {loading && <div className={'EmptyState'}>
        <Loader size='large' color='grey' />
      </div>}
      {!loading && documents && documents.length > 0 && <>
        <div className='RagChatbotDetailPage__documents'>
          {documents.map(doc => <DocumentCard key={doc.id} doc={doc} fetchData={fetchData} crtPage={crtPage} />)}
        </div>
        <div className='RagChatbotDetailPage__pagination'>
          <Pagination
            size={'lg'}
            currentPage={crtPage}
            totalPages={totalPages}
            onPageChange={handlePageChange} />
        </div>
      </>}
      {!loading && documents && documents.length === 0 && <div className={'RagChatbotDetailPage__empty-state'}>
        No Documents
      </div>
      }
      {/* <div className='RagChatbotDetailPage__tabs'>
        <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'file' })} onClick={() => setTab('file')}>
          File
        </div>
        <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'text' })} onClick={() => setTab('text')}>
          Text
        </div>
      </div>
      <div className='RagChatbotDetailPage__knowledge-base-content'>
        {tab === 'file' && fileDocs.map(file => {
          return <div className='RagChatbotDetailPage____knowledge-base--wrap'>
            <div className='RagChatbotDetailPage____knowledge-base--label'>
              Knowledge Base {file.id}
            </div>
            <div className='RagChatbotDetailPage____knowledge-base--file'>
              <FileInput accept=".doc,.pdf,.docx,.txt" file={file}
                setFile={() => { }} seterror={setError} error={error}
                emptyContent={<p>Drag & drop or <span className={"choose-file-text"}>Choose a file</span> to upload<br />
                  Supported File Types: pdf, .doc., docx., .txt
                </p>} />
            </div>
            <div className='RagChatbotDetailPage____knowledge-base--file-preview'>
              {file.content}
            </div>
          </div>
        })}
        {tab === 'text' && textDocs.map(text => {
          return <div className='RagChatbotDetailPage____knowledge-base--wrap'>
            <div className='RagChatbotDetailPage____knowledge-base--label'>
              Knowledge Base {text.id}
            </div>
            <div className='RagChatbotDetailPage____knowledge-base--text'>
              <textarea type="text" placeholder='Enter text here...' className='RagChatbotDetailPage__textarea'
                onChange={() => { }} defaultValue={text.content} />
            </div>
          </div>
        })}
      </div> */}
    </div>
  </div>
}

const NewDocument = (props) => {
  const { fetchData } = props;
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const [tab, setTab] = useState('file');
  const [newData, setNewData] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const handleAdd = async () => {
    setError();
    let text = _.get(newData, 'text');
    let file = _.get(newData, 'file');
    if ((isNil(text) || isEmpty(text)) && isNil(file)) {
      setError('Either file or text must be provided');
      return;
    }
    setLoading(true);
    let count = 0;
    if (!isNil(text) && !isEmpty(text)) {
      try {
        await dispatch(Ai.actions.createChatbotDocument(id, {
          project_id: projectId,
          text
        }))
        setNewData(d => ({ ...d, text: '' }));
        count++;
      } catch (e) {
        setError(`Failed to add documents. Detail: ${e.message}`);
        setLoading(false);
        return;
      }
    }
    if (!isNil(file)) {
      console.log('file:', file)
      try {
        await dispatch(Ai.actions.createChatbotDocument(id, {
          project_id: projectId,
          file
        }))
        setNewData(d => ({ ...d, file: null }));
        count++;
      } catch (e) {
        setError(`Failed to add the document: ${file.path}. Detail: ${e.message}`);
        setLoading(false);
        return;
      }
    }
    toast.success(`The new document${count > 1 ? 's have' : ' has'} been added.`);
    await fetchData();
    setLoading(false);
  }

  const AddButton = () => {
    let text = _.get(newData, 'text');
    let file = _.get(newData, 'file');
    const disabled = (isNil(text) || isEmpty(text)) && isNil(file);

    return <Button className={"RagChatbotDetailPage__new-document--button"} title={loading ? "Adding" : "Add"}
      color='green-outline' onClick={handleAdd} loading={loading} disabled={disabled} />
  }
  return <div className='RagChatbotDetailPage__new-document'>
    <div className='RagChatbotDetailPage__new-document--label'>
      Add a new document
    </div>
    <KnowledgeBase className={'large'} tab={tab} settab={setTab}
      data={newData} setdata={setNewData}
      seterror={setError} error={error} enableReset={true} addButton={<AddButton />} />
    {/* <Button className={"RagChatbotDetailPage__new-document--button"} title={loading ? "Adding" : "Add"}
      color='green-outline' onClick={handleAdd} loading={loading} /> */}
    {error && <div className={cx("RagChatbotDetailPage__document--error")}>{error}</div>}
  </div>
}
const DocumentCard = (props) => {
  const { doc, fetchData, crtPage } = props;
  const { projectId, id } = useParams();
  const type = _.get(doc, 'metadata.type');
  const dispatch = useDispatch();

  const handleDelete = () => {
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteChatbotDocument(projectId, id, doc.id));
      await fetchData(crtPage - 1);
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: doc.id, onConfirm }));
  }

  return <div className='RagChatbotDetailPage__document' key={doc.id}>
    <div className='RagChatbotDetailPage__document--label'>
      <span className='RagChatbotDetailPage__document--label-cell id'>Document ID: {doc.id}</span>
      <span className='RagChatbotDetailPage__document--label-cell'>Type: {type}</span>
      <span className='RagChatbotDetailPage__document--label-cell options'>
        <div className="RagChatbotDetailPage__document--label-icon delete" onClick={handleDelete}>
          <DeleteIcon />
        </div>
      </span>
    </div>
    {type === 'file' && <div className='RagChatbotDetailPage__document--label'>
      <span className='RagChatbotDetailPage__document--label-cell'>
        File name: {_.get(doc, 'metadata.filename')}
      </span>
    </div>}
    <div className='RagChatbotDetailPage__document--hr'></div>
    {/* <div className='RagChatbotDetailPage__document--label'>
      Document Content
    </div> */}
    <div className='RagChatbotDetailPage__document--content'>
      {doc.content}
    </div>
  </div>
}
const FileInput = props => {
  const { setFile, seterror, error, file, baseStyle = BaseStyle, emptyContent, icon } = props;

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: props.accept,
  });
  useEffect(() => {
    if (file) {
      setFile(file);
    }
  }, [])

  useEffect(() => {
    seterror();
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    setFile(newFile);
  }, [acceptedFiles])

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {icon ? icon : ''}
          {file && !error
            ? <div key={file.path} style={{ color: '#18C99D' }}>
              {file.path}
            </div>
            : emptyContent
          }
        </div>
      </div>
    </>
  )
}

const BaseStyle = {
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  fontWeight: 600,
  fontSize: 15,
  borderRadius: 14,
  cursor: 'pointer',
  borderColor: '#282B3B',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: '#636B91',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#18C99D'
};

const acceptStyle = {
  borderColor: '#18C99D'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export default RagChatbotDetailPage;

