import User from '../store/models/User';
import DApp from '../store/models/DApp';
import Video from '../store/models/Video';
import Stream from '../store/models/Stream';
import Bill from '../store/models/Bill';
import TopUp from '../store/models/TopUp';
import Metric from './models/Metric';
import Webhook from './models/Webhook';
import Organization from './models/Organization';
import Project from "./models/Project";
import Ai from './models/Ai';
import TwoFA from "./models/TwoFA";
import {AIShowcase} from "./models/AIShowcase";
import AiStorage from "./models/AiStorage";

const modelTypes = {
  User,
  DApp,
  Video,
  Stream,
  Bill,
  TopUp,
  Metric,
  Webhook,
  Organization,
  Project,
  TwoFA,
  Ai,
  AIShowcase,
  AiStorage,
}

export default modelTypes;
