import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import LandingPageTVA from "./pages/marketing/LandingTVA";
import { Navbar } from "./components/Navbar";
import LoginPage from "./pages/dashboard/Login";
import SignUpPage from "./pages/dashboard/SignUp";
import { useDispatch, useSelector } from "react-redux";
import AppState from "./store/AppState";
import CreateDAppPage from "./pages/dashboard/CreateDapp";
import VideoServicesPage from "./pages/dashboard/video-services/VideoServicesPage";
import CreateVideoPage from "./pages/dashboard/video-services/CreateVideo";
import CreateStreamPage from "./pages/dashboard/video-services/CreateStream";
import DeleteStreamPage from "./pages/dashboard/video-services/DeleteStream";
import Loader from "./components/Loader";
import VideoPage from "./pages/dashboard/video-services/Video";
import StreamPage from "./pages/dashboard/video-services/Stream";
import RequestResetPasswordPage from './pages/dashboard/RequestResetPassword';
import PasswordResetPage from './pages/dashboard/PasswordReset';
import {Features, SettingsUrls, ShowcaseURls, Urls} from "./constants";
import { createSelector } from "reselect";
import _ from "lodash";
import VerifyEmailPage from "./pages/dashboard/VerifyEmailPage";
import { useLocation } from "react-router";
import classNames from "classnames";
import Metrics from "./pages/dashboard/Metrics";
import SubscribePage from "./pages/dashboard/Subscribe";
import SettingsPage from "./pages/dashboard/settings/SettingsPage";
import RegisterWebhookPage from './pages/dashboard/video-services/RegisterWebhook';
import WebhookPage from './pages/dashboard/video-services/Webhook';
import DeleteWebhookRegistrationPage from './pages/dashboard/video-services/DeleteWebhookRegistration';
import { LandingWeb3TheatrePage } from "./pages/marketing/LandingWeb3Theatre";
import { AppSideMenu } from "./components/AppShell";
import AiServicesPage from "./pages/dashboard/ai-services/AiServicesPage";
import RenderingServicesPage from "./pages/dashboard/rendering-services/RenderingServicesPage";
import Modals from "./components/Modals";
import { selectCurrentUserId } from "./store/models/User";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import { selectCurrentOrgId, selectFeaturesEnabled, selectIsFeatureEnabled } from "./store/models/Organization";
import { SelectOrgPage } from "./pages/dashboard/SelectOrgPage";
import { AcceptProjectInvite } from "./pages/dashboard/AcceptProjectInvite";
import { ToastContainer, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ChangeEmailPage } from "./pages/dashboard/ChangeEmailPage";
import NotFoundPage from "./pages/NotFoundPage";
import { LandingPage } from "./pages/marketing/LandingPage";
import { AiShowcasesPage } from "./pages/marketing/AiShowcasesPage";
import { Banner } from './components/Banner';
import UIState, { selectMobileLayout, selectSideMenu } from "./store/UIState";
import { MobileNavbar } from "./components/MobileNavbar";
import { MobileSideMenu } from "./components/SideMenu";
import DeleteVideoPage from "./pages/dashboard/video-services/DeleteVideo";
import { AiShowcasesTermsPage } from './pages/marketing/AiShowcasesTermsPage';
import { AiShowcasesPrivacyPolicyPage } from './pages/marketing/AiShowcasesPrivacyPolicyPage';

const EXCLUDE_SIDEBAR = [
  // Urls.HOME,
  Urls.LOGIN,
  Urls.SIGN_UP,
  Urls.REQUEST_RESET_PASSWORD,
  Urls.VERIFY_ACCOUNT,
  Urls.PASSWORD_RESET,
  Urls.ACCEPT_INVITE,
  Urls.ORGANIZATION_LIST,
  Urls.MARKETING_AI_SHOWCASE_TERMS,
  Urls.MARKETING_AI_SHOWCASE_PRIVACY_POLICY,
  ...ShowcaseURls,
]

const EXCLUDE_NAVBAR = [
  Urls.MARKETING_AI_CONTEST
]

const ProtectedRoute = ({ allowed, orgMissing, redirectTo = Urls.LOGIN, isOrgPage = false, ...props }) => {
  if (!allowed) {
    return <Redirect to={redirectTo} />
  } else if (orgMissing && !isOrgPage) {
    return <Redirect to={Urls.DASHBOARD} />
  } else {
    return <Route {...props} />
  }
}

const loggedInRouteSelector = createSelector(
  selectCurrentUserId,
  selectCurrentOrgId,
  (userId, currentOrgId) => ({ userId, currentOrgId })
)

const appSelector = createSelector(
  selectMobileLayout,
  selectSideMenu,
  (state) => selectFeaturesEnabled(state),
  (isMobile, showSideMenu, featuresEnabled) => ({ isMobile, showSideMenu, featuresEnabled })
)

const LoggedInRoute = ({ isOrgPage, ...props }) => {
  const { userId, currentOrgId } = useSelector(state => loggedInRouteSelector(state));

  const allowed = !_.isNil(userId);
  const orgMissing = _.isNil(currentOrgId)
  return <ProtectedRoute {...props} allowed={allowed} orgMissing={orgMissing} isOrgPage={isOrgPage} />
}

function App() {
  const location = useLocation();
  const pathName = location.pathname;
  const inDashboard = pathName.includes('/dashboard');
  const dispatch = useDispatch();
  const { isMobile, showSideMenu, featuresEnabled } = useSelector(state => appSelector(state));
  const [loading, setLoading] = useState(false);

  // const { billID } = useParams();

  // ON FIRST RENDER
  useEffect(() => {
    // const topUpId = getParam('topUpId');
    // const topUpFlowFinished = getParam('topUpFlowFinished');
    // if (topUpId) {
    //   if (topUpFlowFinished === 'true') {
    //     setTimeout(() => {
    //       // history.push("/dashboard/topup/" + topUpId + "/pending")
    //       history.push("/dashboard/bills")
    //     }, 1500);
    //   } else {

    //   }
    // }

    (async () => {
      try {
        setLoading(true);
        await dispatch(AppState.actions.startApp());
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();

    window?.addEventListener('resize', _.debounce(onUpdateWindowSize, 500));
    return () => window?.removeEventListener('resize', onUpdateWindowSize)
  }, []);


  const onUpdateWindowSize = () => dispatch(UIState.actions.updateLayout(window?.innerWidth, window?.innerWidth))

  const onToggleSideMenu = () => dispatch(UIState.actions.toggleSideMenu());

  if (loading) {
    return <div className={'App App--loading'}>
      <Loader />
    </div>
  }

  return (
    <React.Fragment>
      <div className={classNames('App', {
        'App--dashboard': inDashboard && !isMobile
      })}>
        <div className={'AppShell'}>

          <Switch>
            <Route exact path={EXCLUDE_NAVBAR} /> { /* NO SIDE MENU */}
            <>
              <Banner />
              {!isMobile && <Navbar />}
              {isMobile && <MobileNavbar />}
            </>
          </Switch>

          <div className={'AppShell__Body'}>

            <Switch>
              <Route exact path={EXCLUDE_SIDEBAR} /> { /* NO SIDE MENU */}
              {!isMobile && <Route path="*"><AppSideMenu /></Route>}
            </Switch>

            <MobileSideMenu open={showSideMenu} onClose={onToggleSideMenu} />

            <Switch>
              <Route path={Urls.HOME} component={LandingPage} exact />
              <Route path={Urls.LOGIN} component={LoginPage} exact />
              <Route path={Urls.SIGN_UP} component={SignUpPage} exact />
              <Route path={Urls.REQUEST_RESET_PASSWORD} component={RequestResetPasswordPage} exact />
              <Route path={Urls.VERIFY_ACCOUNT} component={VerifyEmailPage} exact />
              <Route path={Urls.PASSWORD_RESET} component={PasswordResetPage} exact />
              <Route path={Urls.ACCEPT_INVITE} component={AcceptProjectInvite} exact />
              {/* Anonymous pages */}

              <LoggedInRoute path={Urls.DASHBOARD_METRICS} component={Metrics} exact />

              <Route path={Urls.DASHBOARD} component={DashboardPage} exact />
              <Route path={Urls.DASHBOARD_AI} component={AiServicesPage} exact />
              <Route path={Urls.AI_TAB} component={AiServicesPage} exact />
              <Route path={Urls.VIDEO_TAB} component={VideoServicesPage} exact />
              <Route path={Urls.VIDEO_CREATE_VIDEO} component={CreateVideoPage} exact />
              <Route path={Urls.VIDEO_CREATE_LIVESTREAM} component={CreateStreamPage} exact />
              <Route path={Urls.VIDEO_REGISTER_WEBHOOK} component={RegisterWebhookPage} exact />
              <Route path={Urls.DASHBOARD_RENDERING} component={RenderingServicesPage} exact />


              <LoggedInRoute path={Urls.CHANGE_EMAIL} component={ChangeEmailPage} exact />

              <Redirect from={Urls.DASHBOARD} to={Urls.DASHBOARD_VIDEO} exact />
              <LoggedInRoute path={Urls.DASHBOARD_DAPPS_CREATE} component={CreateDAppPage} exact />
              <LoggedInRoute path={Urls.DASHBOARD_SUBSCRIBE} component={SubscribePage} exact />

              <LoggedInRoute path={Urls.ORGANIZATION_LIST} component={SelectOrgPage} exact />

              {/*Video Services*/}
              {/* <LoggedInRoute path={Urls.VIDEO_TAB} component={VideoServicesPage} exact/> */}
              {/* <LoggedInRoute path={Urls.VIDEO_CREATE_VIDEO} component={CreateVideoPage} exact /> */}
              <LoggedInRoute path={Urls.VIDEO_VIEW_VIDEO} component={VideoPage} exact />
              <LoggedInRoute path={Urls.VIDEO_DELETE_VIDEO} component={DeleteVideoPage} exact />
              {/* <LoggedInRoute path={Urls.VIDEO_CREATE_LIVESTREAM} component={CreateStreamPage} exact /> */}
              <LoggedInRoute path={Urls.VIDEO_VIEW_LIVESTREAM} component={StreamPage} exact />
              <LoggedInRoute path={Urls.VIDEO_DELETE_LIVESTREAM} component={DeleteStreamPage} exact />
              {/* <LoggedInRoute path={Urls.VIDEO_REGISTER_WEBHOOK} component={RegisterWebhookPage} exact /> */}
              <LoggedInRoute path={Urls.VIDEO_VIEW_WEBHOOK} component={WebhookPage} exact />
              <LoggedInRoute path={Urls.VIDEO_DELETE_WEBHOOK} component={DeleteWebhookRegistrationPage}
                exact />
              <Route path={Urls.VIDEO_WEB3THEATRE} component={LandingWeb3TheatrePage} exact />


              {/*AI Services*/}
              {/* <LoggedInRoute path={Urls.DASHBOARD_AI} component={AiServicesPage} exact/> */}
              {/* <LoggedInRoute path={Urls.AI_TAB} component={AiServicesPage} exact/> */}
              <LoggedInRoute path={Urls.AI_MODEL_DEPLOYMENT_DETAIL_TAB} component={AiServicesPage} exact />
              <LoggedInRoute path={Urls.AI_CHATBOT_DETAIL_TAB} component={AiServicesPage} exact />
              {featuresEnabled[Features.RAG_CHATBOT] && <LoggedInRoute path={Urls.AI_RAG_CHATBOT_DETAIL} component={AiServicesPage} exact />}
              <LoggedInRoute path={Urls.AI_GPU_NODE_DETAIL} component={AiServicesPage} exact />
              <LoggedInRoute path={Urls.AI_LLM_DETAIL} component={AiServicesPage} exact />
              {featuresEnabled[Features.STORAGE] && <LoggedInRoute path={Urls.AI_STORAGE_DETAILS} component={AiServicesPage} exact />}

              {/*Rendering Services*/}

              {/* Marketing */}
              <Route path={Urls.MARKETING_TVA} component={LandingPageTVA} exact />
              <Route path={Urls.MARKETING_AI_SHOWCASE_TERMS} component={AiShowcasesTermsPage} exact />
              <Route path={Urls.MARKETING_AI_SHOWCASE_PRIVACY_POLICY} component={AiShowcasesPrivacyPolicyPage} exact />
              <Route path={ShowcaseURls} component={AiShowcasesPage} exact />
              <Redirect from={Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_IMAGE} to={Urls.MARKETING_AI_SHOWCASE_ART_STYLE_TRANSFER} exact />

              {/* Settings */}
              <Route path={'/dashboard/settings'}>
                <Switch>
                  <LoggedInRoute path={SettingsUrls} component={SettingsPage} exact />
                </Switch>
              </Route>

              <Route path={"*"} component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </div>
      <Modals />
      <Modals />
      <ToastContainer theme={"dark"} hideProgressBar={true} transition={Zoom} />
    </React.Fragment>
  );
}

export default App;
