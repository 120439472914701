import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import CreatableSelect from 'react-select/creatable';
import Ai, { selectAllLlmEndpoints } from '../store/models/Ai';
import { selectCurrentProjectId } from '../store/models/Project';
import {
  RAGChatbotMaxTemp, RAGChatbotMaxTokens, RAGChatbotMinTemp, RAGChatbotMinTokens,
  RAGChatbotTempStep, RAGChatbotTokenStep, ModalTypes
} from "../constants";
import { selectCurrentUserId } from '../store/models/User';
import UIState from '../store/UIState';
import { toast } from "react-toastify";
import KnowledgeBase from '../components/KnowledgeBase';
import Slider from '../components/Slider';
import { isNil } from 'lodash';

const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: 'white',
    },
    '.selected': {
      borderColor: 'white',
    },
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles, color: "#fff" }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#fff', fontWeight: '500' }),
};

export const CreateRAGChatbotModal = ({ onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [maxTokens, setMaxTokens] = useState(250);
  const [temperature, setTemperature] = useState(0.5);
  const dispatch = useDispatch();
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const standardEndpoints = useSelector(state => selectAllLlmEndpoints(state));
  const endpointOptions = standardEndpoints.map(ep => ({ label: ep.name, value: ep.id }));
  const [knowledgeBase, setKnowledgeBase] = useState();
  const [knowledgeBaseTab, setKnowledgeBaseTab] = useState('file');
  const [customEndpoints, setCustomEndpoints] = useState([]);
  const [costEstimate, setCostEstimate] = useState(null);
  const customEndpointOptions = customEndpoints.map(ep => ({ label: ep.name || ep.id, value: ep.id })).concat({ label: 'Add your own LLM endpoint', value: 'add-custom' });
  let groupedOptions = [{
    label: 'Standard',
    options: endpointOptions
  }, {
    label: 'Custom',
    options: customEndpointOptions
  }]

  const [value, setValue] = useState(_.get(endpointOptions, '0'));
  const [inputValue, setInputValue] = useState('');

  const nameRef = useRef();
  const endpintRef = useRef();

  useEffect(() => {
    (async () => {
      try { 
        const costEstimate = await dispatch(Ai.actions.fetchRagChatbotPrice());
        setCostEstimate(costEstimate);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [])


  const onSubmit = async () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    if (!endpintRef.current.state.selectValue[0]) {
      endpintRef.current.focus();
      setError("LLM Endpoint can't be empty.");
      return;
    }
    let name = nameRef.current.value;
    let endpoint = _.get(endpintRef, 'current.state.selectValue[0].value');

    setLoading(true);
    setError(null);
    try {
      let obj = {
        name: name,
        project_id: projectId,
        llm_endpoint_id: endpoint,
        custom_llm_endpoints: JSON.stringify(customEndpoints),
        max_tokens: maxTokens,
        temperature: temperature,
      };
      let text = _.get(knowledgeBase, 'text');
      let file = _.get(knowledgeBase, 'file');
      if (!isNil(text)) obj.text = text;
      if (!isNil(file)) obj.file = file;
      const chatbot = await dispatch(Ai.actions.createRagChatbot(obj))

      await dispatch(Ai.actions.fetchRagChatbots(projectId));
      onRequestClose();
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }

  const onEndpointChange = (v) => {
    if (v.value === 'add-custom') {
      dispatch(UIState.actions.showModal(ModalTypes.CREATE_LLM_ENDPOINT, { setValue, setCustomEndpoints }))
      return;
    }
    setValue(v);
  }

  const onSetMaxTokens = (event) => {
    const value = event.target.value;
    if (_.isEmpty(value)) {
      setMaxTokens(0)
    } else if (value < RAGChatbotMinTokens) {
      setMaxTokens(RAGChatbotMinTokens)
    } else if (value > RAGChatbotMaxTokens) {
      setMaxTokens(RAGChatbotMaxTokens)
    } else {
      setMaxTokens(value)
    }
  }

  const onSetTemperature = (event) => {
    const value = event.target.value;
    if (value < RAGChatbotMinTemp) {
      setTemperature(RAGChatbotMinTemp)
    } else if (value > RAGChatbotMaxTemp) {
      setTemperature(RAGChatbotMaxTemp)
    } else {
      setTemperature(value)
    }
  }

  return (
    <Modal className={cx("CreateRAGChatbotModal")}
      onRequestClose={onRequestClose}>
      <ModalHeader>
        <ModalTitle align={'row'}>
          Create New RAG Chatbot
          {/* <Tooltip tooltip={'Open documentation'}>
            <a href="https://docs.thetatoken.org/docs/edgecloud-ai-training-with-gpu-nodes" target='_blank'>
              <QuestionMarkIcon />
            </a>
          </Tooltip> */}
        </ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='CreateRAGChatbotModal__row'>
          <div className='CreateRAGChatbotModal__select-wrap endpoint'>
            <div className='CreateRAGChatbotModal__select-label'>LLM Endpoint</div>
            <CreatableSelect
              options={groupedOptions}
              className={"CreateRAGChatbotModal__selector"}
              placeholder={"LLM Endpoint, e.g. thetalabsorg/ubuntu-sshd:latest"}
              styles={selectStyles}
              onChange={onEndpointChange}
              ref={endpintRef}
              value={value}
            />
          </div>
        </div>
        <div className='CreateRAGChatbotModal__row'>
          <div className='CreateRAGChatbotModal__select-wrap name'>
            <div className='CreateRAGChatbotModal__select-label'>Chatbot Name&nbsp;<div className='text-optional'>(optional)</div></div>
            <input className={"CreateRAGChatbotModal__input"}
              placeholder={'Enter RAG chatbot name'}
              ref={nameRef}
            />
          </div>
          <div className='CreateRAGChatbotModal__select-wrap slider'>
            <div className='CreateRAGChatbotModal__select-label'>Max Tokens</div>
            <Slider className='CreateRAGChatbotModal__slider-wrap' step={RAGChatbotTokenStep} id="maxTokensId"
              max={RAGChatbotMaxTokens} min={RAGChatbotMinTokens} value={maxTokens} onChange={onSetMaxTokens} />
          </div>
          <div className='CreateRAGChatbotModal__select-wrap slider'>
            <div className='CreateRAGChatbotModal__select-label'>Temperature</div>
            <Slider className='CreateRAGChatbotModal__slider-wrap' step={RAGChatbotTempStep} id="temperatureId"
              max={RAGChatbotMaxTemp} min={RAGChatbotMinTemp} value={temperature} onChange={onSetTemperature} />
          </div>
        </div>
        <div className='CreateRAGChatbotModal__row'>
          <div className='CreateRAGChatbotModal__select-wrap'>
            <div className='CreateRAGChatbotModal__select-label'>Knowledge base&nbsp;<div className='text-optional'>(optional)</div></div>
            <KnowledgeBase tab={knowledgeBaseTab} settab={setKnowledgeBaseTab}
              data={knowledgeBase} setdata={setKnowledgeBase}
              seterror={setError} error={error} />
          </div>
        </div>

        <div className='CreateRAGChatbotModal__price-info'>
          <div className='CreateRAGChatbotModal__price-info--note'>
            Cost Estimation
          </div>
          <div className='CreateRAGChatbotModal__price-info--price'>
            {costEstimate ? `${costEstimate.prompt_tokens} input tokens, and ${costEstimate.completion_tokens} output tokens` : '-'}
          </div>
        </div>

        {error && <div className={cx("CreateRAGChatbotModal__error")}>{error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={"Create RAG Chatbot"} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}