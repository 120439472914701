import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import Select from 'react-select';
import { selectCurrentProjectId } from '../store/models/Project';
import { selectCurrentUserId } from '../store/models/User';
import AiStorage from '../store/models/AiStorage';
import { toast } from 'react-toastify';
import { generateRandomIdWithPrefix } from '../utils';

const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
    backgroundColor: isDisabled ? '#1e2231' : 'transparent',
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: '#636B91',
    },
    '.selected': {
      borderColor: '#3D4463',
    },
    cursor: isDisabled ? 'not-allowed' : 'default',
    backgroundColor: isDisabled ? '#1e2231' : 'transparent',
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#636B91', fontWeight: '500' }),
};

export const StorageModal = ({ onRequestClose, existingVolume = null }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const regions = useSelector(state => state.models.regions);

  const nameRef = useRef();
  const locationRef = useRef();
  const sizeRef = useRef();
  const [sizeUnit, setSizeUnit] = useState({ value: 'Gi', label: 'GB' });

  const isEditing = !!existingVolume;

  useEffect(() => {
    if (!isEditing) {
      dispatch(AiStorage.actions.fetchRegions());
    }
  }, [projectId]);

  useEffect(() => {
    if (isEditing) {
      nameRef.current.value = existingVolume.DisplayName;
      locationRef.current?.setValue({ value: existingVolume.Region, label: existingVolume.Region });
      const sizeMatch = existingVolume.StorageCapacity.match(/(\d+)(Gi|Ti)/);
      if (sizeMatch) {
        sizeRef.current.value = sizeMatch[1];
        setSizeUnit({ value: sizeMatch[2], label: sizeMatch[2] === 'Gi' ? 'GB' : 'TB' });
      }
    }
  }, [existingVolume]);

  const handleSizeInput = (e) => {
    const key = e.key;
    if (!/^\d*$/.test(key) && key !== 'Backspace' && key !== 'Delete') {
      e.preventDefault();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    const name = nameRef.current?.value;
    const location = locationRef.current?.getValue()?.[0]?.value || locationRef.current?.getValue()?.value;
    const size = sizeRef.current?.value;
    const unit = sizeUnit.value;

    if (!location || !size) {
      setError('Please fill in all fields');
      setLoading(false);
      return;
    }

    try {
      const body = {
        region: location,
        displayName: name,
      };

      if (!isEditing) {
        body.name = generateRandomIdWithPrefix('vol', 20);
      }

      if (!isEditing || (isEditing && existingVolume.StorageCapacity !== `${size}${unit}`)) {
        body.storageCapacity = `${size}${unit}`;
      }

      let response;
      if (isEditing) {
        response = await dispatch(AiStorage.actions.updateStorage(projectId, existingVolume.Suffix, existingVolume.StorageCapacity, existingVolume.DisplayName, body));
      } else {
        response = await dispatch(AiStorage.actions.createStorage(projectId, body));
      }

      if (response.error) {
        throw new Error(response.error);
      }

      toast.success(`Volume ${isEditing ? 'updated' : 'created'} successfully`);
      dispatch(AiStorage.actions.fetchStorages(projectId));

      onRequestClose();
    } catch (err) {
      console.error(`Error ${isEditing ? 'updating' : 'creating'} volume:`, err);
      setError(err.message || `An error occurred while ${isEditing ? 'updating' : 'creating'} the volume`);
    } finally {
      setLoading(false);
    }
  }

  const sizeUnitOptions = [
    { value: 'Gi', label: 'GB' },
    { value: 'Ti', label: 'TB' },
  ];

  return (
    <Modal className={cx("VolumeModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle align={'row'}>
          {isEditing ? 'Edit Volume' : 'Create New Volume'}
        </ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='VolumeModal__row'>
          <div className='VolumeModal__select-wrap name'>
            <div className='VolumeModal__select-label'>Volume Name (optional)</div>
            <input className={"VolumeModal__input"}
              placeholder={'Enter volume name'}
              ref={nameRef}
              autoFocus
            />
          </div>
        </div>
        <div className='VolumeModal__row'>
          <div className='VolumeModal__select-wrap location'>
            <div className='VolumeModal__select-label'>Region</div>
            <Select options={regions.map(region => ({ value: region, label: region }))}
              className={"VolumeModal__selector"}
              styles={selectStyles}
              placeholder={'Select Region'}
              ref={locationRef}
              isDisabled={isEditing}
            />
          </div>
        </div>
        <div className='VolumeModal__row'>
          <div className='VolumeModal__select-wrap size'>
            <div className='VolumeModal__select-label'>Volume Size</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className={"VolumeModal__input"}
                style={{ flex: 1, marginRight: '10px' }}
                placeholder={'Enter volume size'}
                ref={sizeRef}
                type="number"
                min="1"
                step="1"
                onKeyDown={handleSizeInput}
              />
              <Select
                options={sizeUnitOptions}
                value={sizeUnit}
                onChange={setSizeUnit}
                styles={selectStyles}
                className={"VolumeModal__small-selector"}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className='VolumeModal__price-info'>
          {/* <div className='VolumeModal__price-info--note'>
            Cost Estimation
          </div>
          <div className='VolumeModal__price-info--price'>
            - per hour.
          </div> */}
        </div>
        {error && <div className={cx("VolumeModal__error")}>{error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={isEditing ? "Update Volume" : "Create New Volume"} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}